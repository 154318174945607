<template>
    <section>
        <modal ref="modalCambiarCedis" titulo="Cambiar Cedis" icon="shop" @guardar="updateDatos">
            <cargando v-if="cargando" />
            <div class="row mx-0 justify-center my-2">
                <div class="col-8">
                    <p class="f-13 text-general pl-3">Seleccione Centro de distribución</p>
                    <el-select v-model="model.id_cedis" filterable size="small" class="w-100">
                        <el-option
                        v-for="item in cedis"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
            </div>
        </modal>
    </section>
</template>
<script>
import CambioDatos from "~/services/tiendas/cambio_datos";
import Cedis from "~/services/cedis";

export default {
    data(){
        return{
            cargando: false,
            model: {
                id_cedis:null
            },
            cedis:[]
        }
    },
    computed:{
        id_tienda(){
            return Number(this.$route.params.id_tienda)
        }
    },
    methods: {
        toggle(item){
            this.model.id_cedis = item.id_cedis
            this.selectCedis()
            this.$refs.modalCambiarCedis.toggle();
        },
        async selectCedis(){
            try {
                const {data} = await Cedis.get_cedis()
                this.cedis = data.cedis

            } catch (error){
                this.error_catch(error)
            }
        },
        async updateDatos(){
            try {
                if(this.model.id_cedis === null){
                    this.notificacion('Mensaje', 'El cedis es requerido', 'warning')
                    return false
                }
                this.cargando = true;
                const {data} = await CambioDatos.updateCedis(this.id_tienda,this.model)
                this.notificacion('Mensaje', 'Informacion actualizaada', 'success')
                this.$emit('actualizar')
                this.$refs.modalCambiarCedis.toggle()
                this.model.id_cedis = null

            } catch (e){
                this.error_catch(e)
            } finally{
                this.cargando = false;
            }
        },
    }
}
</script>
